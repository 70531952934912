<template>
  <div class="main" :class="show ? 'h5main' : ''">
    <mtop />
    <div class="demo_top"></div>
    <div class="demo_box1">
      <div class="box1_top">
        <div class="img"></div>
        <div>
          您的当前位置： 网站首页 >
          <span @click="toPage('/about')">关于我们 </span>>人才招聘
        </div>
      </div>
      <div class="box1_bottom">
        <div class="bottom_left">
          <div class="left_title">职位筛选</div>

          <div class="left_seach">
            <van-search
              v-model="value"
              show-action
              placeholder="请输入搜索关键词"
              @cancel="onCancel"
              class="input"
            >
              <template #action>
                <div @click="onSearch" class="btn">搜索职位</div>
              </template>
            </van-search>
          </div>

          <div class="cont">
            <div class="list">
              <div class="item_title">最新职位</div>
              <div
                class="item_item"
                v-for="(item, index) in list"
                :key="index"
                @click="getMes(item.id)"
              >
                <div class="item_con">
                  <div class="ji" v-if="item.is_hot==1">急</div>
                 {{item.title}}
                </div>
                <div class="tips">
                  <span v-for="(txt, index) in item.label" :key="index">{{txt}} | </span> 
                  发布于 {{item.create_time}}</div>
              </div>
            </div>
          </div>

          <el-pagination
            v-show="listQuery.total > 1"
            :page-size.sync="listQuery.page_size"
            :current-page.sync="listQuery.page"
            @pagination="getList"
            :background="!show"
            class="page"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="listQuery.total"
          >
          </el-pagination>
        </div>
        <div class="bottom_right">
          <div class="right_item">
            <div class="item_title">最新职位</div>
            <div
              class="item_item"
              v-for="(item, index) in rightList"
              :key="index"
              @click="getMes(item.id)"
            >
              <div class="item_con">
                <div class="ji" v-if="item.is_hot==1">急</div>
                {{item.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mbottom />
  </div>
</template>
 
 <script>
import { connectTron, walletOfOwner, tokenURI } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {
      actInfo: {},
      rightkey: [],
      rightValue: [],
      rightList: [],
      id: null,
      list: [],
      active: "",
      show: false,
      name: "",
      formdata: {
        Page: 1,
        PageSize: "20",
      },
      menu: "",
      loading: false,
      finished: false,
      refreshing: false,
      showlist: false,
      mylist: {},
      basemenu: "",
      listQuery: {
        page: 1,
        page_size: 5,
        total: 0,
        type: 2, //新闻
        search:''
      },
      value: "",
    };
  },
  components: {
    mtop,
    mbottom,
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: { 
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.getList();
    this.getRlist();
  },
  methods: {
   getRlist(){
      this.$get({
        url: "recruitList",
        data:{},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.rightList = res.data.list.data;
            }
          });
        },
      });
   },
    onSearch() {
      this.listQuery.search=this.value
      this.listQuery.page=1
      this.getList()
    },
    onCancel() {},
    toPage(e) {
      this.$router.replace({ path: e });
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getMes(val) {
      this.id = val;
      sessionStorage.setItem("recuiDetail", val);
      this.$router.push({
        name: "recuiDetail",
        params: {
          detail: val,
        },
      });
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$toast("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$toast(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    seach() {
      this.formdata = {
        Name: this.name,
        Page: 1,
        PageSize: "20",
      };
      this.menu = this.basemenu;
      this.$forceUpdate();
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    tochange(e, j, i) {
      this.menu[i].list.forEach((el) => {
        if (j == el.name) {
          el.show = true;
        } else {
          el.show = false;
        }
      });
      this.$set(this.menu[i], "child", j);
      console.log(e, j);
      if (JSON.stringify(this.formdata).indexOf(e) != -1) {
        this.formdata[e] = j;
      } else {
        var a = { [e]: j };
        this.formdata = Object.assign(a, this.formdata);
      }
      this.onRefresh();
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    res() {
      this.formdata = {
        Page: 1,
        PageSize: 20,
      };
      this.name = "";
      this.getList();
      this.onRefresh();
    },
    goopen(e) {
      let url =
        "https://opensea.io/assets/matic/0xb5e200d739bc792e273b2beb6dea9fd918ede79b/" +
        e;
      window.open(url);
    },
    onRefresh() {
      this.formdata.Page = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.formdata.Page == 1) {
        this.list = [];
        this.refreshing = false;
      }
      this.$get({
        url: "cupid",
        data: this.formdata,
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              let reg = new RegExp("ipfs://", "g");
              var list = res.data.items.map((el) => {
                el.url = el.image.replace(
                  reg,
                  "https://www.cupidlovemetaverse.com/ipfs/"
                );
                return el;
              });

              this.loading = false;
              if (this.formdata.Page > 1) {
                this.list = [...this.list, ...list];
              } else {
                this.list = list;
              }
              // console.log(this.list);

              if (res.data.items.length == 20) {
                this.formdata.Page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
    },
    getList() {
      this.listQuery.cate_id=sessionStorage.getItem('conid')
      this.$get({
        url: "recruitList",
        data: this.listQuery,
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.list = res.data.list.data;
              this.listQuery.total=res.data.list.total
            }
          });
        },
      });
    },
    tochild(e, s) {
      this.menu.forEach((el, i) => {
        if (e == i) {
          el.show = !s;
        } else {
          el.show = false;
        }
      });
      this.$forceUpdate();

      // this.$set(this.menu[e],"show",!s)
    },
    toshowList() {
      var address = this.$store.getters.userWallet;
      if (!address) {
        this.wallet();
        return;
      }
      walletOfOwner(this.$store.getters.userWallet).then((res) => {
        // console.log("res",res);
        this.basedata = res;
        if (res.length > 0) {
          this.showlist = true;
          res.forEach((el) => {
            this.getUrl(el);
          });
          // this.list.length=res.length
          // console.log(this.list);
        }
      });
    },
    closes() {
      this.showlist = false;
    },
    getUrl(e) {
      tokenURI(e).then((res) => {
        let regs = new RegExp("https://ipfs.io/", "g");
        var urls = res.replace(regs, "https://www.cupidlovemetaverse.com/");
        this.$axios({
          method: "GET",
          url: urls,
        }).then((response) => {
          // console.log(response.data);
          let reg = new RegExp("ipfs://", "g");
          var url = response.data.image.replace(
            reg,
            "https://www.cupidlovemetaverse.com/ipfs/"
          );
          this.mylist[e] = url;

          this.$forceUpdate();
        });
      });
    },
  },
};
</script>
 
 
 <style lang="scss" scoped>
$t-mf: "Arial";

.el-icon-arrow-down:before {
  content: "";
}
.main {
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  min-width: 1200px;
  max-width: 100%;
  .demo_top {
    width: 100%;
    height: 85px;
    background: #000000;
  }
  .demo_box1 {
    width: 1200px;
    margin: 0 auto;
    .box1_top {
      display: flex;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 50px;

      align-items: center;
      .img {
        width: 24px;
        height: 24px;
        background: url("../assets/indeximg/point.png");
        background-size: 100% 100%;
        margin-right: 12px;
      }
      div > span {
        color: #0052da;
        cursor: pointer;
      }
    }
    .box1_bottom {
      width: 1200px;
      min-height: 100vh;
      border: 1px solid #f3f3f3;
      margin: 0 auto;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      // align-items: center;
      .bottom_left {
        width: 824px;
        // height: 1429px;
        min-height: 360px;
        background: #ffffff;
        box-sizing: border-box;
        padding: 40px 30px;
        overflow: auto;
        .left_title {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          text-align: left;
        }
        .left_seach {
         margin: 20px 0;
          .btn {
            width: 88px;
            height: 32px;
            background: #2b6bff;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .left_time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          text-align: left;
          line-height: 60px;
        }
        .cont {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          background: #ffffff;
          line-height: 30px;
          text-align: left;
          margin: 30px 0px;
          .item_title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;
          }
          .item_item {
            padding: 12px 0;
            border-bottom: 1px dashed #ebedf0;
            cursor: pointer;
            .item_con {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #646566;
              line-height: 20px;
              display: flex;
              align-items: center;
              margin: 12px 0;
              .ji {
                width: 20px;
                height: 20px;
                background: #df4545;
                border-radius: 2px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
              }
            }
            .item_con:hover {
              color: #2b6bff;
            }
          }
        }
        .tips {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c8c9cc;
          line-height: 20px;
        }
      }
      .bottom_right {
        width: 362px;
        // height: 1429px;
        min-height: 360px;
        background: #ffffff;
        box-sizing: border-box;
        padding: 30px 20px;
        .right_item {
          width: 315px;
          max-height: 284px;
          cursor: pointer;
          .item_title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 30px;
            text-align: left;
            margin-top: 10px;
          }
          .item_item {
            border-bottom: 1px dashed #ebedf0;
            padding: 15px 0;

            .item_con {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              text-align: left;
              display: flex;
              align-items: center;
              cursor: pointer;
              .ji {
                width: 20px;
                height: 20px;
                background: #df4545;
                border-radius: 2px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
              }
            }
            .item_con:hover {
              color: #2b6bff;
            }
            .item_date {
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 30px;
              text-align: left;
              line-height: 45px;
            }
          }
        }
      }
    }
  }

  .page {
    display: flex;
    justify-content: center;
    text-align: left;
    margin-top: 30px;
  }
}
.h5main {
  width: 100%;
  min-width: 100%;
  .demo_top{
   height: 125px;
  }
  .demo_box1 {
    width: 100%;
    .box1_top {
      line-height: 88px;
      font-size: 26px;
      padding-left: 10px;
    }
    .box1_bottom {
      width: 100%;
      padding: 10px;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      .bottom_left {
        width: auto;
        .left_title {
          font-size: 32px;
        }
        .left_seach {
          .input {
            .van-search__content {
              height: 68px;
              line-height: 68px;
            }
            .van-cell {
              align-items: center;
              font-size: 26px;
              .van-field__left-icon .van-icon {
                font-size: 26px !important;
              }
            }
          }
          .btn {
            font-size: 26px;
            width: 130px;
            height: 68px;
          }
        }
        .cont {
          .list {
            .item_title {
              font-size: 28px;
            }
            .item_item {
              margin: 20px 0;
              padding-bottom: 20px;
              .item_con {
                .ji {
                  font-size: 26px;
                  width: auto;
                  height: auto;
                  padding: 10px;
                }
                font-size: 26px;
              }
              .tips {
                font-size: 24px;
              }
            }
          }
        }
      }
      .bottom_right {
        width: auto;
        margin-top: 60px;
        .right_item {
          width: auto;
          max-height: 100%;
          .item_title {
            font-size: 26px;
            margin: 20px 0;
          }
          .item_item {
            .item_con {
              line-height: 66px;
              font-size: 26px;
              .ji {
                font-size: 26px;
                width: auto;
                height: 25px;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
  .page {
    margin-top: 30px;
    padding: 30px;
    background: #ffffff;
    ::v-deep {
      i,
      li {
        font-size: 32px;
        padding: 0 20px;
      }
    }
  }
}
</style>